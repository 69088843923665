import { $ } from '../../../shared/js/utils/dom';

class ScrollToProject {
  init = () => {
    window.addEventListener('load', this.scrollToProject);
  };

  scrollToProject = event => {
    const target = window.location.hash;

    if (!target) return;

    target.replace('#', '');
    window.location.hash = '';

    const element = $(target);
    const topPos = element.getBoundingClientRect().top + window.scrollY - 100;

    const startScroll = () => {
      window.scrollTo({
        top: topPos,
        behavior: 'smooth',
      });
    };

    setTimeout(startScroll, 500);
  };
}
export default ScrollToProject;
