// import styles
import 'src/shared/scss/index.scss';

import MobileNav from './components/page-components/header/navigation';
import PageContent from './components/page-components/page-content/page-content';
import CategoryIntro from './components/page-components/category-intro/category-intro';
import BackToTop from './components/page-components/top-button/top-button';
import ScrollToProject from './components/page-components/project-list/project-list';

// only available in dev mode
if (process.env.NODE_ENV !== 'production') require('src/shared/js/dev/');

const mobileNav = new MobileNav();
mobileNav.init();

const pageContent = new PageContent();
pageContent.init();

const categoryIntro = new CategoryIntro();
categoryIntro.init();

const backToTop = new BackToTop();
backToTop.init();

const startTop = new ScrollToProject();
startTop.init();
