import { $ } from '../../../shared/js/utils/dom';
import Shower from '../../../shared/js/modules/shower';

class CategoryIntro {
  constructor(params) {
    this.categoryToggle = $('.category-intro__button');
    this.mainWrapper = $('body');
    this.showers = [];
  }

  init = () => {
    this.categoryToggle &&
      this.categoryToggle.addEventListener(
        'click',
        event => this.toggleCategoryIntroDescription(this.categoryToggle),
        false
      );
    this.categoryToggle && this.initShowers();
  };

  initShowers = () => {
    if (this.showers.length !== 0) return;

    const target = $('.category-intro__description--additional');
    const handler = this.categoryToggle;

    const shower = new Shower({
      target,
      handler,
      easing: 'easeInOutQuart',
      duration: 400,
      initOpened: false,
    });
    shower.init();
    this.showers.push(shower);
  };

  toggleCategoryIntroDescription = categoryToggle => {
    if (categoryToggle.classList.contains('category__description--is-active')) {
      categoryToggle.classList.remove('category__description--is-active');
      categoryToggle.innerHTML = '<span>mehr erfahren</span>';
    } else {
      categoryToggle.classList.add('category__description--is-active');
      categoryToggle.innerHTML = '<span>weniger anzeigen</span>';

      this.initShowers();
    }
  };
}
export default CategoryIntro;
